(function($) {
    $(document).ready(function() {

        var mql = window.matchMedia('(max-width: 425px)');
        var socialmenu = $('#menu-footer-social');
        if(mql.matches) {
            socialmenu.removeClass('mr-auto').addClass('mx-auto');
        }

    });
})(jQuery);