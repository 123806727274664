(function($) {
    $(document).ready(function() {

        $('.testimonials').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            variableWidth: false,
            arrows: true,
            prevArrow: '<button class="slick-prev"><i class="fas fa-arrow-left"></i></button>',
            nextArrow: '<button class="slick-next"><i class="fas fa-arrow-right"></i></button>',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    arrows: false,
                    dots: true
                  }
                }
            ]
            //prevArrow: $('.prev'),
            //nextArrow: $('.next')
          });

    });
})(jQuery);
