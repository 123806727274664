(function($) {
    $(document).ready(function() {
        var banner = $('.banner');
        var imgChange = $('.imgbrand');
        var wind = window.location.origin;
        var baseUrl = '<?php echo get_template_directory_uri(); ?>';
        //console.log(wind);

        $(window).on("scroll", function() {
            if($(window).scrollTop() > 100) {
                banner.addClass('header-active');
                imgChange.attr('src', wind + '/wp-content/themes/wp/assets/img/MIBA-Icon-2023.png');
            } else {
                //remove the background property so it becomes transparent again (defined in your css)
               banner.removeClass("header-active");
               imgChange.attr('src', wind + '/wp-content/themes/wp/assets/img/MIBA-logo-2023.png');
            }
        });
    });
})(jQuery);