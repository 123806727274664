(function($) {
    $(document).ready(function() {
        var width = $(window).width();
        activetab = $('.fl-tabs').find('.fl-tab-active');
        activetablabel = $('.fl-tabs-label').find('.fas');

        // activetab = jQuery('.fl-tabs').find('.fl-tab-active');
        if (width <= 767) {
            activetablabel.addClass('fa-plus');
            activetab.removeClass('fl-tab-active');
        }


        // if (width <= 767) {
        //     activetab.find('.fas').addClass('fa-plus');
        //     activetab.removeClass('fl-tab-active');

        //     if(activetab){
        //         $(this).activetab.find('.fas').addClass('fa-minus');
        //     } 
        // }
        
        $('.fl-tab-active').click( function(){
            console.log( 'I was clicked' );
            //this.activetab.find('.fas').addClass('fa-plus');
            //this.activetab.removeClass('fl-tab-active');
        });

        if (width <= 425) {
            $('#menu-footer-utility').addClass('text-center');
            $('#menu-footer').addClass('justify-content-center');
        }
    });
})(jQuery);